import { createMuiTheme, ThemeOptions } from '@material-ui/core';
import { DefaultTheme } from 'styled-components';

type Theme = ThemeOptions & { variables: any; overrides: { MUIDataTable: any } };

export const theme = createMuiTheme({
  typography: {
    fontFamily: '"Barlow Semi Condensed", sans-serif',
  },
  palette: {
    primary: {
      main: '#B22E41',
    },
    secondary: {
      main: '#FFF',
      contrastText: 'rgba(0, 0, 0, 0.8)',
      dark: '#F0F0F0',
    },
    text: {
      // Minimum contrast ration o 7:1. It passes the AAA test for small font-size
      secondary: 'rgba(0, 0, 0, 0.68)',
    },
    background: {
      default: '#FFF',
    },
  },
  variables: {
    headerHeight: '4rem',
  },
  overrides: {
    MuiButton: {
      contained: {
        borderRadius: '1.5rem',
        padding: '0.4rem 2rem',
      },
      outlined: {
        borderRadius: '1.5rem',
        padding: '0.4rem 2rem',
      },
      sizeLarge: {
        padding: '0.6rem 3rem',
        borderRadius: '1.75rem',
        ['@media (max-width: 600px)']: {
          padding: '0.6rem 1rem',
        },
      },
      sizeSmall: {
        padding: '0.2rem 1rem',
      },
      root: {
        textTransform: 'none',
        fontWeight: 'bold',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '1rem',
      },
    },
    MuiCard: {
      root: {
        borderRadius: '1rem',
      },
    },
    MuiOutlinedInput: {
      input: {
        height: '1rem',
        lineHeight: '1rem',
        padding: '1rem',
      },
      inputMultiline: {
        height: 'auto',
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '3rem',
        fontWeight: 600,
        ['@media (max-width: 600px)']: {
          fontSize: '2rem !important',
        },
      },
      h2: {
        fontSize: '2.25rem',
        fontWeight: 600,
        ['@media (max-width: 600px)']: {
          fontSize: '1.75rem !important',
        },
      },
      h3: {
        fontSize: '1.75rem',
        fontWeight: 500,
        ['@media (max-width: 600px)']: {
          fontSize: '1.5rem !important',
        },
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 500,
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 500,
      },
      h6: {
        fontSize: '1.125rem',
        fontWeight: 500,
      },
      subtitle1: {
        fontWeight: 600,
      },
      subtitle2: {
        fontWeight: 600,
      },
    },
    MuiDialog: {
      paper: {
        margin: '1rem',
      },
      paperFullWidth: {
        ['@media (max-width: 695.95px)']: {
          margin: '0.5rem',
          width: 'calc(100% - 1rem) !important',
          maxWidth: 'calc(100% - 1rem) !important',
          maxHeight: 'calc(100% - 1rem) !important',
        },
      },
    },
    MuiToolbar: {
      gutters: {
        ['@media (min-width: 600px)']: {
          paddingLeft: '2rem',
          paddingRight: '2rem',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '0.5rem 1rem',
      },
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none',
      },
    },
  },
} as Theme) as DefaultTheme;
