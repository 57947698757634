import * as React from 'react';
import App from 'next/app';
// import { NextWebVitalsMetric } from 'next/app';
import { theme } from '@app/util/theme';
import { ThemeProvider, StylesProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import '@app/styles/index.scss';
import i18n from '../i18n';
import { PageLoader } from '@app/components/loaders/page-loader';
import { trackView } from '@app/util/pixel-event';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import SentryInit from '@app/util/sentry';
import GeneralSnackbar from '@app/util/show-snackbar';

const { captureException } = SentryInit();

// export const reportWebVitals = (metric: NextWebVitalsMetric) => {
//   console.log(metric)
// }

class CustomApp extends App {
  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles != null) {
      jssStyles.parentNode!.removeChild(jssStyles);
    }

    // Tawk to initialization
    // (window.Tawk_API = window.Tawk_API || {}),
    //   (window.Tawk_API.onLoad = () => {
    //     window.Tawk_API.hideWidget();
    //   });
    // window.Tawk_LoadStart = new Date();
    // const s1: any = document.createElement('script');
    // const s0: any = document.getElementsByTagName('script')[0];
    // s1!.async = true;
    // s1!.src = 'https://embed.tawk.to/5d2ddf86bfcb827ab0cc0f9a/default';
    // s1!.charset = 'UTF-8';
    // s1!.setAttribute('crossorigin', '*');
    // s0!.parentNode!.insertBefore!(s1, s0);

    // Track every page except the realty detail page, that has its onw tracker
    const pathname = window.location.pathname;
    const split = pathname.split('/');
    const lastPath = split[split.length - 1];
    if (
      !pathname.includes('imoveis') &&
      (lastPath.length === 0 || !Number.isNaN(parseInt(lastPath, 10)))
    ) {
      trackView();
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    captureException(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StyledThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <Component {...pageProps} />
            <PageLoader />
            <GeneralSnackbar />
          </StylesProvider>
        </StyledThemeProvider>
      </ThemeProvider>
    );
  }
}

export default i18n.appWithTranslation(CustomApp);
