import { useState, useEffect } from 'react';
import Router from 'next/router';
import NProgress from 'nprogress';
import { trackView } from '@app/util/pixel-event';

export const usePageLoader = (delay: number = 200) => {
  const [timer, setTimer] = useState<any>();
  const routeChangeStart = () => {
    clearTimeout(timer);
    setTimer(setTimeout(NProgress.start, delay));
  };
  const routeChangeEnd = () => {
    clearTimeout(timer);
    NProgress.done();
    if (window.gtag != null) {
      window.gtag('set', 'page', window.location.pathname);
      window.gtag('send', 'pageview');
    }
    trackView();
  };

  const routeChangeError = () => {
    clearTimeout(timer);
    NProgress.done();
  };

  useEffect(() => {
    Router.events.on('routeChangeStart', routeChangeStart);
    Router.events.on('routeChangeComplete', routeChangeEnd);
    Router.events.on('routeChangeError', routeChangeError);
    NProgress.configure({
      showSpinner: false,
    });
    return () => {
      clearTimeout(timer);
      Router.events.off('routeChangeStart', routeChangeStart);
      Router.events.off('routeChangeComplete', routeChangeEnd);
      Router.events.off('routeChangeError', routeChangeError);
    };
  });
};
