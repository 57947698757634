import React from 'react';
import EventEmitter, { Emitter } from 'event-emitter';

import { Snackbar } from '@app/components/feedback/snackbar';

const emitter: Emitter = EventEmitter();

export const showSnackbar = (options: any) => {
  emitter.emit('show', options);
};

export const showSuccessSnackbar = (options: any) => {
  showSnackbar({ type: 'success', ...options });
};

export const showDangerSnackbar = (options: any) => {
  showSnackbar({ type: 'danger', ...options });
};

const GeneralSnackbar: React.FunctionComponent = ({}) => {
  const [options, setOptions] = React.useState<any>(null);

  React.useEffect(() => {
    const onShow = (newOptions: any) => {
      setOptions(newOptions);
    };
    emitter.on('show', onShow);

    return () => {
      emitter.off('show', onShow);
    };
  }, []);

  return (
    <Snackbar
      autoHideDuration={3000}
      {...options}
      open={Boolean(options)}
      onClose={() => {
        if (options != null && options.onClose != null) {
          options.onClose();
        }
        setOptions(null);
      }}
    />
  );
};

export default GeneralSnackbar;
